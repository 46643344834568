<!--
 * @Author: Link
 * @Date: 2020-12-15 09:15:19
 * @LastEditTime: 2021-11-02 10:28:17
-->
<template>
  <div class="page">
    <topbar title="等级说明" />
    <!-- <img src="@/assets/gradeDesc.jpg" alt="" />
    <img src="@/assets/合伙人教程-1.jpg" alt="" />
    <img src="@/assets/合伙人教程-2.jpg" alt="" />
    <img src="@/assets/合伙人教程-3.jpg?v=12" alt="" />
    <img src="@/assets/合伙人教程-4.jpg" alt="" />
    <img src="@/assets/合伙人教程-5.jpg" alt="" /> -->
    <div v-html="desc"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desc: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.push('/home')
    },
    getData() {
      this.$http.get('/boom-center-admin-service/app/distribution/general-setting').then(res => {
        this.desc = res
      })
    }
  }
}
</script>

<style lang="less" scope>
.page {
  min-height: 100vh;
  img {
    width: 100%;
    height: auto;
  }
  .content {
    padding: 10px;
  }
  .back {
    margin: 20px;
  }

  .intro {
    margin: 20px 0;
    .title {
      font-weight: bold;
    }
    .content {
      text-indent: 2rem;
      color: #666666;
    }
  }
  .grade {
    margin: 20px 0;
    .title {
      font-weight: bold;
    }
    .teamNumber {
      text-indent: 2rem;
      font-weight: bold;
    }
    .content {
      text-indent: 2rem;
      color: #666666;
    }
  }
  .tip {
    padding: 15px;
    color: #999;
    font-size: 10px;
    p {
      margin-bottom: 10px;
    }
  }
}
</style>
